<template>
  <div>
    <div class="grid grid-cols-3 gap-4">
      <div class="lg:col-span-2 col-span-3 clearfix">
        <div class="float-left flex gap-1 items-center whitespace-nowrap lg:mt-4 clearfix">
          <div class="cursor-pointer">
            <div v-if="stockRecall.status === 'submitted'" class="flex px-4 py-2 status-btn" @click="addTracking('ready')">
              <span class="text-sm">{{ $t('Ready') }}</span>
            </div>
          </div>
          <div class="cursor-pointer">
            <div v-if="stockRecall.status === 'ready'" class="flex px-4 py-2 status-btn" @click="addTracking('outbounded')">
              <span class="text-sm">{{ $t('Outbound') }}</span>
            </div>
          </div>
          <div class="cursor-pointer">
            <div v-if="stockRecall.status === 'outbounded'" class="flex px-4 py-2 status-btn" @click="addTracking('recalled')">
              <span class="text-sm">{{ $t('Recall') }}</span>
            </div>
          </div>
          <download-button class="mr-4 float-left whitespace-nowrap" :totalRows="totalRows"></download-button>
        </div>
        <shipblu-table
          :sst="true"
          multiple
          v-model="selected"
          pagination
          :max-items="maximumItems"
          @search="handleSearch"
          @keyup.enter.native="handleSearch"
          search
          :data="stockProducts"
          :tableLoader="tableLoader"
        >
          <template slot="thead">
            <shipblu-th>{{$t('Product Image')}}</shipblu-th>
            <shipblu-th>{{$t('ShipBlu Inventory ID')}}</shipblu-th>
            <shipblu-th>{{$t('SKU')}}</shipblu-th>
            <shipblu-th>{{$t('Product Name')}}</shipblu-th>
            <shipblu-th>{{$t('Size')}}</shipblu-th>
            <shipblu-th>{{$t('Color')}}</shipblu-th>
            <shipblu-th>{{$t('Dimensions')}}</shipblu-th>
            <shipblu-th>{{$t('Weight')}}</shipblu-th>
            <shipblu-th>{{$t('Outbounded Quantity')}}</shipblu-th>
            <shipblu-th>{{$t('Flags')}}</shipblu-th>
            <shipblu-th>{{$t('Actions')}}</shipblu-th>
          </template>

          <template slot-scope="{ data }">
            <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <shipblu-td :data="data[indextr].image">
                <img :src="data[indextr].product ? data[indextr].product.image : ''" class="w-10 h-10"/>
              </shipblu-td>

              <shipblu-td :data="data[indextr].requestID">
                {{ data[indextr].product.id }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].sku">
                {{ data[indextr].product.sku }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].productName">
                {{ data[indextr].product.name }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].size">
                {{ data[indextr].product.size }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].color">
                <div class="color-container h-6 w-3/4">
                  <div class="w-full h-full" :style="`background-color:${data[indextr].product.color}`"></div>
                </div>
              </shipblu-td>

              <shipblu-td :data="data[indextr].dimensions">
                {{ data[indextr].product.d_length }} x {{ data[indextr].product.d_width }} x {{ data[indextr].product.d_height }}
              </shipblu-td>
            
              <shipblu-td :data="data[indextr].size">
                {{ data[indextr].product.weight }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].inboundedQuantity">
                {{ data[indextr].outbounded_quantity }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].flags">
                <p v-for="(item, index) in data[indextr].flags" :key="index">
                  {{ item.name }}
                </p>
              </shipblu-td>

              <shipblu-td>
                <div @click="removeAttribute($event)">
                  <vs-dropdown
                    vs-trigger-click
                    class="dd-actions cursor-pointer"
                    @click="selected = []"
                  >
                    <vs-button
                      type="border"
                      size="small"
                      icon-pack="feather"
                      icon="icon-more-horizontal"
                    >
                    </vs-button>
                    <vs-dropdown-menu style="width: 190px">
                      <vs-dropdown-item
                        :class="stockRecall.status === 'ready' && data[indextr].outbounded_quantity === 0 ? 'text-blue-100' : 'disabled'"
                        @click="openStorageUnitModal(data[indextr])"
                      >
                        <span class="flex gap-2 items-center">
                          <feather-icon
                            icon="EditIcon"
                            svgClasses="h-6 w-6"
                          />
                          <span>{{$t('Outbound QTY')}}</span>
                        </span>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
              </shipblu-td>
            </shipblu-tr>
          </template>
        </shipblu-table>
        <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
      </div>

      <div class="lg:col-span-1 col-span-3">
        <shipblu-card :cardLoading="stockRecallLoading" class="lg:mt-5 p-5">
          <p class="text-blue-900 text-xl font-medium leading-tight mb-4">{{$t('Stock Recall Info')}}</p>
          <div>
            <span class="text-darkgray text-lg mr-1">{{$t('Fulfillment Center')}}:</span>
            <span class="text-primary text-lg font-medium">{{stockRecall.fulfillment_center.name}}</span>
          </div>
          <div class="mt-1 flex items-center">
            <span class="text-darkgray text-lg mr-1">{{$t('Number Of Vehicles')}}:</span>
            <span v-if="!editable" class="text-primary text-lg font-medium">{{stockRecall.num_vehicles}}</span>
            <div v-else class="ml-2">
              <vs-input-number min="1" v-validate="'required|numeric'" name="no. of vehicles" v-model.number="stockRecall.num_vehicles" class="stock-request-input-number flex justify-start"/>
              <p class="text-danger text-sm" v-show="errors.has('no. of vehicles')">{{ errors.first('no. of vehicles') }}</p>
            </div>
          </div>
          <div class="mt-1">
            <span class="text-darkgray text-lg mr-1">{{$t('Merchant')}}:</span>
            <span class="text-primary text-lg font-medium">{{stockRecall.merchant ? stockRecall.merchant.name : 'N/A'}}</span>
          </div>
          <div class="mt-1">
            <span class="text-darkgray text-lg mr-1">{{$t('Merchant ID')}}:</span>
            <span class="text-primary text-lg font-medium">{{stockRecall.merchant ? stockRecall.merchant.id : 'N/A'}}</span>
          </div>
          <div class="mt-1">
            <span class="text-darkgray text-lg mr-1">{{$t('Total SKUs')}}:</span>
            <span class="text-primary text-lg font-medium">{{stockRecall.number_of_skus}}</span>
          </div>
          <div class="mt-1">
            <span class="text-darkgray text-lg mr-1">{{$t('Total Quantity')}}:</span>
            <span class="text-primary text-lg font-medium">{{stockRecall.total_quantity}}</span>
          </div>
        </shipblu-card>
        <stock-request-tracking :stockRecall="stockRecall" :trackings="trackings" @loadTrackings="loadTrackings" :trackingsLoading="trackingsLoading"/>
      </div>
    </div>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      :active.sync="storageUnitsModal"
      :title="$t('Storage Units')"
      :buttons-hidden="true">
      <div class="mb-6 grid grid-cols-3 gap-4">
        <div v-for="storageUnit in storageUnits" :key="storageUnit.index" class="flex justify-between gap-4 border border-solid border-grey rounded-md py-2 px-4">
          <p>{{storageUnit.storage_unit}}</p>
          <p>{{storageUnit.stored_quantity}}</p>
        </div>
      </div>
      <div class="text-center">
        <vs-button @click="outboundProduct()">{{$t('Outbound SKU')}}</vs-button>
      </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import {sendFulfillmentRequest} from '../../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import i18nData from '../../../i18n/i18nData.js'
import ShipbluCard from '../../../layouts/components/ShipbluCard.vue'
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'
import StockRequestTracking from '../components/StockRequestTracking.vue'
import DownloadButton from '../components/DownloadStock.vue'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'

export default {
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      editable: false,
      trackings: [],
      trackingsLoading: false,
      stockProducts: [],
      stockRecall: {
        fulfillment_center: {},
        merchant: {}
      },
      stockRecallLoading: false,
      stockProduct: {},
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' ',
      stockStatus: '',
      tableLoader: false,
      outboundProductObj: {},
      storageUnitsModal: false,
      storageUnits: []
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluCard,
    ShipbluPagination,
    StockRequestTracking,
    DownloadButton,
    ShipbluPrompt
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadStockProducts()
    }
  },
  methods: {
    openStorageUnitModal (data) {
      sendFulfillmentRequest(false, false, this, `api/v2/fc/products-in-storage-unit?sku=${data.product.sku}`, 'get', null, true,
        (response) => {
          this.outboundProductObj = data
          this.storageUnits = response.data.results
          this.storageUnitsModal = true
        }
      )
    },
    outboundProduct () {
      sendFulfillmentRequest(false, false, this, `api/v1/fc/stock-recalls/${this.$route.params.stockID}/products/${this.outboundProductObj.id}/`, 'patch', {current_inventory_level: this.outboundProductObj.product.current_inventory_level}, true,
        () => {
          this.storageUnitsModal = false
          this.loadStockProducts()
        }
      )
    },
    closeModal () {
      this.storageUnitsModal = false
    },
    addTracking (status) {
      this.stockStatus = status
      switch (status) {
      case 'outbounded':
        this.$vs.dialog({
          color: 'success',
          title: 'Confirm',
          text: 'Are you sure you want to confirm outbounding?',
          accept: this.confirmAddTracking
        })
        break
      case 'recalled':
        this.$vs.dialog({
          color: 'success',
          title: 'Confirm',
          text: 'Are you sure you want to confirm recall this stock recall?',
          accept: this.confirmAddTracking
        })
        break
      case 'ready':
        this.confirmAddTracking()
        break
      }
    },
    confirmAddTracking () {
      const tracking = {
        stock_recall: this.$route.params.stockID,
        status: this.stockStatus
      }
      sendFulfillmentRequest(false, false, this, `api/v1/fc/stock-recalls/${this.$route.params.stockID}/tracking/`, 'post', tracking, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Stock request'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
            position: 'top-center'
          })
          this.stockStatus = ''
          this.loadTrackings()
          if (this.stockStatus === 'ready') {
            let submittedStocks = localStorage.getItem('createdStockRequests')
            submittedStocks = Number(submittedStocks) - 1
            localStorage.setItem('createdStockRequests', submittedStocks)
          }
        }
      )
    },
    removeAttribute (event) {
      event.preventDefault()
    },
    cancelEdit () {
      this.editable = !this.editable
      this.loadStockRecall()
    },
    loadStockRecall () {
      this.stockRecallLoading = true
      sendFulfillmentRequest(true, false, this, `api/v1/fc/stock-recalls/${this.$route.params.stockID}/`, 'get', null, true,
        (response) => {
          this.stockRecall = response.data
          this.stockRecallLoading = false
        }
      )
    },
    loadStockProducts () {
      this.searchInProgress = true
      this.tableLoader = true
      const query = `?offset=${this.offset}&search=${this.searchVal}&limit=${this.maximumItems}`
      sendFulfillmentRequest(true, false, this, `api/v1/fc/stock-recalls/${this.$route.params.stockID}/products/${query}`, 'get', null, true,
        (response) => {
          this.stockProducts = response.data.results
          this.tableLoader = false
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    },
    loadTrackings () {
      this.trackingsLoading = true
      sendFulfillmentRequest(true, false, this, `api/v1/fc/stock-recalls/${this.$route.params.stockID}/tracking/`, 'get', null, true,
        (response) => {
          this.trackings = response.data.results
          this.stockRecall.status = this.trackings[0].status
          this.trackingsLoading = false
        }
      )
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadProductSearch)
    },
    loadProductSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadStockProducts()
    }
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadStockRecall()
    this.loadStockProducts()
    this.loadTrackings()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.shipment-modal {
  .vs-dialog {
    max-width: 800px !important;
    max-height: 90%;
  }
}
.con-pagination-table{
  display: none;
}
.disabled {
  color: #C5CBD0;
  pointer-events: none;
}
.vx-timeline {
  margin-left: 0.5rem;
  padding-left: 40px;
  border-left: 2px dotted #e5e8eb;
  li {
    position: relative;
    .timeline-icon {
      position: absolute;
      top: 0;
      left: -3.2rem !important;
      border-radius: 50%;
      padding: 0px;
      padding: 0.3rem !important;
      padding-bottom: 0.4rem;
    }
  }
}
.product-order-status, .activity-e-status {
  margin-left: -30px;
}
.color-container {
  border: 1px solid #000;
  border-radius: 7px;
  padding: 2px;
  margin: auto !important;
  div {
    border-radius: 5px;
  }
}
</style>