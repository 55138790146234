<template>
  <div class="shipblu-card">
    <div v-if="cardLoading" class="card-loader"></div>
    <slot v-else></slot>
  </div>
</template>

<script>
export default {
  props: ['cardLoading'],
  data () {
    return {}
  }
}
</script>

<style lang="scss">
.shipblu-card {
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  .header {
    font-weight: 500;
    font-size: 18px;
    text-transform: capitalize;
    color: #2C2C2C;
    line-height: 25px;
  }
  div.order-view-input {
    .vs-con-input .vs-input--input {
      height: 37.77px;
    }
  }
  .package-card {
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
  }
}
.card-loader {
  position: relative;
  border-radius: 5px;
  height: 170px;
  overflow: hidden;
  &:before {
    content: '';
    height: 110px;
    display: block;
    background-color: #ededed;
    box-shadow: -48px 78px 0 -48px #ededed, -51px 102px 0 -51px #ededed;
  }
  &:after {
    content: '';
    background-color: #333;
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    animation: loader-animate 1s infinite linear;
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 30%, rgba(255,255,255,0) 81%);
    background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 30%, rgba(255,255,255,0) 81%);
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 30%, rgba(255,255,255,0) 81%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 );
  }
}
@keyframes loader-animate{
  0% {transform: translate3d(-100%, 0, 0);}
  100% {transform: translate3d(100%, 0, 0);}
}
.separator-line {
  position: absolute;
  top: 0px;
  left: calc(50% - 0.5px);
  height: 100%;
  border-left: 1px solid rgba(157, 173, 194, 0.22);
  &.notes {
    left: calc(33.33% - 2px);
  }
}
</style>